import {Card, Divider} from "@mui/material";
import {PropsWithChildren} from "react";
import {Title} from "./setup/Title";
import {Stepper} from "./setup/Stepper";

export function CardTemplate(props: PropsWithChildren<{
    title?: string;
    description?: string;
    next?: string;
    nextSoftDisabled?: boolean;
}>) {
    return (
        <Card style={{display: "flex", flexDirection: "column", maxWidth: 600, marginTop: 24, marginBottom: 24, flexShrink: 0}}>
            {props.title && <Title>{props.title}</Title>}
            {props.description && <div style={{marginLeft: 16, marginRight: 16, marginBottom: 8, fontSize: "0.8rem", marginTop: -4,}}>
                {props.description}
            </div>}
            {props.title && <Divider />}
            {props.children}
            {props.next && <Divider />}
            {props.next && <Stepper next={props.next} nextSoftDisabled={props.nextSoftDisabled} />}
        </Card>
    )
}