import { CardTemplate } from "../../CardTemplate";
import {Frame} from "../../../Frame";

export function Fail() {
    return (
        <Frame>
            <CardTemplate>

                <div style={{padding: 16}}>
                    <div style={{fontSize: 24, paddingBottom: 8}}>Error!</div>
                    <div style={{paddingBottom: 8}}>That didn't work</div>
                </div>

            </CardTemplate>
        </Frame>
    )
}