import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {grey} from "@mui/material/colors";
import {useNavigate} from "react-router-dom";

export function Stepper(props: {
    next: string;
    nextSoftDisabled?: boolean;
}) {
    const nav = useNavigate()

    return (
        <div style={{padding: 8}}>
            <Grid container spacing={1} justifyContent="space-between" alignItems={"center"}>
                <Grid item>
                    {/*<div style={{fontSize: "0.8rem", fontWeight: "500", marginLeft: 8}}>*/}
                    {/*    Step {props.value} of 4*/}
                    {/*</div>*/}
                </Grid>
                <Grid item>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={() => nav(props.next)}
                                    color={props.nextSoftDisabled ? "inherit" : "primary"}
                                    style={{backgroundColor: props.nextSoftDisabled ? grey["400"]: undefined }} variant="contained">Next Step</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}