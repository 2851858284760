import List from "@mui/material/List";
import {ListItemButton, ListItemText} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {PropsWithChildren} from "react";
import { grey } from "@mui/material/colors";
import { css } from '@emotion/css';


export function Sidebar() {

    return (
        <div style={{paddingLeft: 4, paddingRight: 4, borderRight: "1px solid " + grey["300"], marginRight: 16, backgroundColor: grey["100"]}}>
            <List>
                <SidebarItem title="Setup" link="/setup">
                    <SidebarItem title="Open Sidebar" link="/setup/open-sidebar" />
                    <SidebarItem title="Grant Access" link="/setup/grant-access" />
                </SidebarItem>

                <SidebarItem title="Guide" link="/guide">
                    <SidebarItem title="Auto Fill" link="/guide/autofill" />
                    <SidebarItem title="Suggestions" link="/guide/suggestions">
                        <SidebarItem title="Inspector" link="/guide/suggestions/inspector" />
                    </SidebarItem>
                    <SidebarItem title="Notes" link="/guide/notes" />
                </SidebarItem>
            </List>
        </div>
    )
}

function SidebarItem(props: PropsWithChildren<{
    title: string;
    link: string;
}>) {
    const loc = useLocation();
    return (
        <>
            <Link to={props.link} className={linkCls}>
                <ListItemButton selected={loc.pathname === props.link}>
                    <ListItemText primary={props.title} />
                </ListItemButton>
            </Link>
            <div style={{paddingLeft: 16}}>
                {props.children}
            </div>
        </>
    )
}

const linkCls = css({
    textDecoration: "none", color: 'initial',
    "& .MuiButtonBase-root": {
        borderRadius: 4,
    }
})