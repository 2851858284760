import {useEffect, useRef} from "react";

export function useWindowEvent(name: string, callback: () => void) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    useEffect(() => {
        const innerCallback = () => {
            callbackRef.current();
        }

        callbackRef.current();

        window.addEventListener(name, innerCallback)
        return () => window.removeEventListener(name, innerCallback)
    }, [name]);
}