import {Grid, Typography} from "@mui/material";
import {CardTemplate} from "../CardTemplate";
import {Frame} from "../../Frame";

export function SendOff() {
    return (
        <Frame>
            <CardTemplate>
                <div style={{padding: 16, paddingBottom: 0}}>
                    <Typography style={{marginBottom: 8}} variant={"h5"}>
                        You’re off to the races. Go get ’em you testing superhero! 🚀
                    </Typography>
                    <Typography style={{marginBottom: 8}}>
                        If you have questions or feedback, please reach out to <a href="mailto:nate@testingtaxi.com">nate@testingtaxi.com</a>. <br />I look forward to hearing from you!
                    </Typography>
                </div>
                <div style={{height: 16}} />
                <div style={{padding: 16}}>
                    <Typography style={{marginBottom: 8}} variant={"h6"}>
                        Helpful Links
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item>
                            <a href="https://testingtaxi.com">Testing Taxi</a>
                        </Grid>
                        <Grid item>
                            <a href="https://testing.taxi/chrome-whats-new/">What's New</a>
                        </Grid>
                        <Grid item>
                            <a href="https://twitter.com/natebosscher">twitter/@natebosscher</a>
                        </Grid>
                    </Grid>
                </div>
            </CardTemplate>
        </Frame>
    )
}