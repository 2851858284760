
import React, {useState} from "react";
import { Frame } from "../../../Frame";
import {
    Button,
    Grid,
    Popover,
    TextField,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Card
} from "@mui/material";
import {css} from "@emotion/css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useNavigate} from "react-router-dom";


export function Popout() {
    const nav = useNavigate();
    const [show, setShow] = useState<any>(null);
    const data = JSON.parse(sessionStorage.getItem("data") || "[]");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [note, setNote] = useState("");

    return (
        <Frame>
            <div style={{padding: 20, paddingTop: 50, paddingLeft: 0}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">People List</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" style={{marginRight: 8}} onClick={() => {
                            sessionStorage.setItem("data", "[]");
                            nav("/demo/form-checker");
                        }}>
                            Reset
                        </Button>
                        <Button variant="contained"
                                color="primary"
                                onClick={(e) => {
                                    setShow(e.target)
                                }}
                                endIcon={<KeyboardArrowDownIcon />}
                        >Create Item</Button>
                    </Grid>
                </Grid>
            </div>

            <Card style={{marginRight: 20, flex: 1, overflow: "auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant="head">Name</TableCell>
                            <TableCell variant="head">Email</TableCell>
                            <TableCell variant="head">Phone</TableCell>
                            <TableCell variant="head">Note</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>John Doe</TableCell>
                            <TableCell>john@doe.com</TableCell>
                            <TableCell>555-555-5555</TableCell>
                            <TableCell>John is a great guy</TableCell>
                        </TableRow>
                        {data.map((d: any, index: number) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{d.name}</TableCell>
                                    <TableCell>{d.email}</TableCell>
                                    <TableCell>{d.phone}</TableCell>
                                    <TableCell>{d.note}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Card>

            {show && <Popover anchorEl={show} onClose={() => setShow(null)} open>
                <div style={{padding: 16}}>
                    <div style={{paddingBottom: 8}}>Demo Add</div>
                    <form id={"my-form"} onSubmit={e => {
                        e.preventDefault();
                        const d = data.slice(0);
                        d.push({
                            name: firstName + " " + lastName,
                            email,
                            phone,
                            note
                        })

                        sessionStorage.setItem("data", JSON.stringify(d));

                        nav("/demo/form-checker/success");

                    }}>
                        <TextField label="First Name" variant="outlined" onChange={e => setFirstName(e.target.value)} fullWidth name="first_name" /> <div className={formSpace}></div>
                        <TextField label="Last Name" variant="outlined" onChange={e => setLastName(e.target.value)} fullWidth name="last_name" /> <div className={formSpace}></div>
                        <TextField label="Email" variant="outlined" required onChange={e => setEmail(e.target.value)} fullWidth name="email" /> <div className={formSpace}></div>
                        <TextField label="Phone" variant="outlined" onChange={e => setPhone(e.target.value)} fullWidth name="phone" /> <div className={formSpace}></div>
                        <TextField label="Message" variant="outlined" onChange={e => setNote(e.target.value)} fullWidth name="message" multiline rows={4} /> <div className={formSpace}></div>
                        <Button type="submit">Submit</Button>
                    </form>
                </div>
            </Popover>}
        </Frame>
    )
}

const formSpace = css({
    height: 16,
})