import {Card, Grid, Typography} from "@mui/material";
import {CardTemplate} from "../CardTemplate";
import {Frame} from "../../Frame";

export function Notes() {
    return (
        <Frame>
            <CardTemplate title={"Notes"} next={"/guide/sendoff"}>
                <div style={{padding: 16, paddingBottom: 0}}>
                    <Typography style={{marginBottom: 8}}>
                        Notes are a way to keep track of things in the page's context.
                    </Typography>
                    <Typography style={{marginBottom: 8}}>
                        The inspector will also give links to focus the element in the browser or focus it in Chrome Devtools.
                    </Typography>
                    <Typography style={{marginBottom: 8}}>
                        Go ahead add a note to this page. Click away to another page in the app and come back. You'll see
                        your note section update.
                    </Typography>
                </div>
                <div style={{height: 16}} />
                <Grid container spacing={1}>
                    <Grid item xs>
                        <img src={require("./page-notes.webp")} style={{width: "100%"}} />
                    </Grid>
                </Grid>
            </CardTemplate>
        </Frame>
    )
}