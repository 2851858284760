import React from 'react';
import {
    createBrowserRouter, Navigate,
    RouterProvider,
} from "react-router-dom";
import {OpenSidebar} from "./pages/setup/OpenSidebar";
import { GrantAccess } from './pages/setup/GrantAccess';
import { AutoFill } from './pages/guide/AutoFill';
import { Suggestions } from './pages/guide/Suggestions';
import {Inspector} from "./pages/guide/Inspector";
import { Notes } from './pages/guide/Notes';
import { SendOff } from './pages/guide/SendOff';
import {SetupGetStarted} from "./pages/setup/SetupGetStarted";
import {FormChecker} from "./pages/formChecker/FormChecker";
import {Basic} from "./pages/formChecker/basic/Basic";
import { Success } from './pages/formChecker/basic/Success';
import { Fail } from './pages/formChecker/basic/Fail';
import {AddToList} from "./pages/formChecker/basic/AddToList";
import {Popout} from "./pages/formChecker/popout/Popout";


const router = createBrowserRouter([
    {
        path: "/setup",
        children: [{
            index: true,
            element: <SetupGetStarted />
        }, {
            path: "open-sidebar",
            element: <OpenSidebar />
        },{
            path: "grant-access",
            element: <GrantAccess />
        }]
    }, {
        path: "/guide",
        children: [{
            index: true,
            element: <Navigate to="autofill" />
        }, {
            path: "autofill",
            element: <AutoFill />
        }, {
            path: "suggestions",
            children: [{
                index: true,
                element: <Suggestions />,
            }, {
                path: "inspector",
                element: <Inspector />
            }]
        }, {
            path: "notes",
            element: <Notes />
        }, {
            path: "sendoff",
            element: <SendOff />
        }]
    }, {
        path: "/",
        element: <Navigate to="/setup" />
    }, {
        path: "/demo/form-checker",
        children: [{
            index: true,
            element: <FormChecker />
        }, {
            path: "basic",
            element: <Basic />
        },{
            path: "popout",
            element: <Popout />
        }, {
            path: "add-to-list",
            element: <AddToList />
        }, {
            path: "success",
            element: <Success />
        }, {
            path: "fail",
            element: <Fail />
        }]
    }
]);

export const primaryYellow = "#FBD604"

function App() {
  return (<RouterProvider router={router} />);
}

export default App;
