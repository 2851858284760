import React, {useState} from "react";
import { Frame } from "../../../Frame";
import {Button, TextField} from "@mui/material";
import {css} from "@emotion/css";
import {CardTemplate} from "../../CardTemplate";

export function AddToList() {
    const [list, setList] = useState<{[p: string]: FormDataEntryValue}[]>([]);

    return (
        <Frame>
            {list.map(l => <CardTemplate>
                <div style={{padding: 16}}>
                    {Object.keys(l).map(k =>
                        <div key={k}>
                            {k}: {l[k].toString()}
                        </div>
                    )}
                </div>
            </CardTemplate>)}
            <CardTemplate>
                <div style={{padding: 16}}>
                    <div style={{paddingBottom: 8}}>Demo Form</div>
                    <form id={"my-form"} key={list.length.toString()} onSubmit={e => {
                        e.preventDefault();

                        const formData = new FormData(e.target as any);
                        const data = Object.fromEntries(formData.entries());
                        console.log(data);
                        setList([...list, data]);
                    }}>
                        <TextField label="First Name" variant="outlined" fullWidth name="first_name" /> <div className={formSpace}></div>
                        <TextField label="Last Name" variant="outlined" fullWidth name="last_name" /> <div className={formSpace}></div>
                        <TextField label="Email" variant="outlined" fullWidth name="email" /> <div className={formSpace}></div>
                        <TextField label="Phone" variant="outlined" fullWidth name="phone" /> <div className={formSpace}></div>
                        <TextField label="Message" variant="outlined" fullWidth name="message" multiline rows={4} /> <div className={formSpace}></div>
                        <Button type="submit">Submit</Button>
                    </form>
                </div>
            </CardTemplate>
        </Frame>
    )
}

const formSpace = css({
    height: 16,
})