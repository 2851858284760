import { Frame } from "../../../Frame";
import { CardTemplate } from "../../CardTemplate";

export function Success() {
    return (
        <Frame>
            <CardTemplate>

                <div style={{padding: 16}}>
                    <div style={{fontSize: 24, paddingBottom: 8}}>🎉 Success!</div>
                    <div style={{paddingBottom: 8}}>It was submitted</div>
                </div>

            </CardTemplate>
        </Frame>
    )
}