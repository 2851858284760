import React, {PropsWithChildren} from "react";
import {grey} from "@mui/material/colors";
import {Button, Grid} from "@mui/material";
import {Sidebar} from "./pages/Sidebar";
import {primaryYellow} from "./App";

export function Frame(props: PropsWithChildren<{}>) {
    return (
        <div style={{position: "absolute", top: 0, left: 0, height: "100%", width: "100%", display: "flex", flexDirection: "column", backgroundColor: grey["200"]}}>
            <header style={{width: "100%", backgroundColor: primaryYellow}}>
                <div style={{margin: 10}}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <img src={"/logo.webp"} className="App-logo" alt="logo" style={{height: 50}} />
                        </Grid>
                        <Grid item>
                            <div style={{fontWeight: "bold", fontSize: "1rem"}}>
                                Cab Labs
                            </div>
                            <div style={{fontSize: "0.6rem"}}>
                                A <a href="https://testing.taxi">Testing Taxi</a> Training Ground
                            </div>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item>
                            <a href="https://testing.taxi"><Button>Back to Main Site</Button></a>
                        </Grid>
                    </Grid>
                </div>
            </header>
            <div style={{flex: 1, display: "flex", flexDirection: "row", alignItems: "stretch", overflow: "hidden"}}>
                <Sidebar />
                <div style={{flex: 1, display: "flex", flexDirection: "column", overflow: "auto"}}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}