import React, {useState} from "react";
import { Frame } from "../../../Frame";
import {Button, TextField} from "@mui/material";
import {css} from "@emotion/css";
import {CardTemplate} from "../../CardTemplate";
import {useNavigate} from "react-router-dom";

export function Basic() {
    const nav = useNavigate();

    return (
        <Frame>
            <CardTemplate>
                <div style={{padding: 16}}>
                    <div style={{paddingBottom: 8}}>Demo Form</div>
                    <form id={"my-form"} onSubmit={e => {
                        e.preventDefault();
                        nav("/demo/form-checker/success");
                    }}>
                        <TextField label="First Name" variant="outlined" fullWidth name="first_name" /> <div className={formSpace}></div>
                        <TextField label="Last Name" variant="outlined" fullWidth name="last_name" /> <div className={formSpace}></div>
                        <TextField label="Email" variant="outlined" required fullWidth name="email" /> <div className={formSpace}></div>
                        <TextField label="Phone" variant="outlined" fullWidth name="phone" /> <div className={formSpace}></div>
                        <TextField label="Message" variant="outlined" fullWidth name="message" multiline rows={4} /> <div className={formSpace}></div>
                        <Button type="submit">Submit</Button>
                    </form>
                </div>
            </CardTemplate>
        </Frame>
    )
}

const formSpace = css({
    height: 16,
})