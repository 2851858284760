import {CardTemplate} from "../CardTemplate";
import {Frame} from "../../Frame";
import Button from "@mui/material/Button";
import {Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";

export function SetupGetStarted() {
    const nav = useNavigate()

    return (
        <Frame>
            <CardTemplate>
                <div style={{padding: 16}}>
                    <Typography variant="h6">Welcome to Testing Taxi!</Typography>
                    <Typography variant="body2">This guide will walk you through the basics of using Testing Taxi to test your app.</Typography>
                    <div style={{height: 10}} />
                    <Button onClick={() => nav("/setup/open-sidebar")} variant="contained" color="primary">Let's Go!</Button>
                </div>
            </CardTemplate>
        </Frame>
    )
}