import { Frame } from "../../Frame";
import {CardTemplate} from "../CardTemplate";
import {Link} from "react-router-dom";

export function FormChecker() {
    return (
        <Frame>
            <CardTemplate title="Form Checker">
                <ul style={{padding: 16}}>
                    <li><Link to="add-to-list">Add To List</Link></li>
                    <li><Link to="basic">basic</Link></li>
                    <li><Link to="popout">popout</Link></li>
                </ul>
            </CardTemplate>
        </Frame>
    )
}