import {Card, Grid, Typography} from "@mui/material";
import {CardTemplate} from "../CardTemplate";
import {Frame} from "../../Frame";

export function Suggestions() {
    return (
        <Frame>
            <CardTemplate title={"Suggestions"} next={"/guide/suggestions/inspector"}>
                <div style={{padding: 16, paddingBottom: 0}}>
                    <Typography style={{marginBottom: 8}}>
                        Test Suggestions give deeper and more thorough way to test your app. Suggestions are
                        displayed based on what you have showing in your browser window.
                    </Typography>
                    <Typography style={{marginBottom: 8}}>
                        Go ahead and click one to see what it's like.
                    </Typography>
                </div>
                <div style={{height: 16}} />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <img src={require("./click-suggestion.jpg")} style={{width: "100%"}} />
                    </Grid><Grid item xs={6}>
                        <img src={require("./suggestion-detail.jpg")} style={{width: "100%"}} />
                    </Grid>
                </Grid>
            </CardTemplate>
        </Frame>
    )
}