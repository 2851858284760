import {CardTemplate} from "../CardTemplate";
import React from "react";
import { Frame } from "../../Frame";
import {Button, FormLabel, Grid, TextField, Typography} from "@mui/material";
import {css} from "@emotion/css";
import { DropZone } from "../DropZone";


export function AutoFill() {
    return (
        <Frame>
            <CardTemplate
                title="Auto Fill"
                description="A super fast way to build data and jump through forms"
                next="/guide/suggestions">
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <div style={{padding: 16}}>
                            <Typography style={{marginBottom: 8}}>
                                Testing Taxi can fill forms based on what type of information it thinks makes sense.
                            </Typography>
                            <Typography style={{marginBottom: 8}}>
                                Just focus the form you want and press the Generate / Current Form button in the sidebar
                            </Typography>
                            <Typography>
                                Here. Try on the demo from on the right
                            </Typography>
                        </div>

                        <div style={{height: 12}} />
                        <img src={require("./autofill.gif")} style={{width: "100%"}} />
                    </Grid>
                    <Grid item xs>
                        <div style={{padding: 16, paddingTop: 80}}>
                            <div style={{paddingBottom: 8}}>Demo Form</div>
                            <form>
                                <TextField label="First Name" variant="outlined" fullWidth name="first_name" /> <div className={formSpace}></div>
                                <TextField label="Last Name" variant="outlined" fullWidth name="last_name" /> <div className={formSpace}></div>
                                <TextField label="Email" variant="outlined" fullWidth name="email" /> <div className={formSpace}></div>
                                <TextField label="Phone" variant="outlined" fullWidth name="phone" /> <div className={formSpace}></div>
                                <TextField label="Message" variant="outlined" fullWidth name="message" multiline rows={4} /> <div className={formSpace}></div>
                                <FormLabel>Profile Image</FormLabel>
                                <DropZone />
                                <div style={{height: "20px"}}></div>
                                <Button type="submit">Submit</Button>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </CardTemplate>
        </Frame>
    )
}

const formSpace = css({
    height: 16,
})