import {Card, Grid, Typography} from "@mui/material";
import {CardTemplate} from "../CardTemplate";
import {Frame} from "../../Frame";

export function Inspector() {
    return (
        <Frame>
            <CardTemplate title={"Inspector"} next={"/guide/notes"}>
                <div style={{padding: 16, paddingBottom: 0}}>
                    <Typography style={{marginBottom: 8}}>
                        Some suggestions have an inspector. This is a way to see specific elements that need to be tested
                        or fail our automated checks.
                    </Typography>
                    <Typography style={{marginBottom: 8}}>
                        The inspector will also give links to focus the element in the browser or focus it in Chrome Devtools.
                    </Typography>
                    <Typography style={{marginBottom: 8}}>
                        Go ahead and click one to see what it's like.
                    </Typography>
                </div>
                <div style={{height: 16}} />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <img src={require("./run-inspector.webp")} style={{width: "100%"}} />
                    </Grid><Grid item xs={6}>
                    <img src={require("./image-inspection-issue.webp")} style={{width: "100%"}} />
                </Grid>
                </Grid>
            </CardTemplate>
        </Frame>
    )
}