import { Frame } from "../../Frame";
import {CardTemplate} from "../CardTemplate";
import {useEffect, useState} from "react";

export function GrantAccess() {
    const [isEnabled, setIsEnabled] = useState(false);
    const [delay, setDelay] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setDelay(true);
        }, 500)
    }, []);

    return (
        <Frame>
            <CardTemplate title="Grant Access" next="/guide/autofill" nextSoftDisabled={!isEnabled}>
                <img style={{width: "100%"}} src={require("./grant-access.png")} alt="Grant access button" />
            </CardTemplate>

            {!isEnabled && delay && <iframe style={{height: 1, width: 100}} ref={r => {
                if(!r) return;

                r.contentWindow?.addEventListener("message", e => {
                    if(JSON.stringify(e.data).indexOf("taxiSetup") !== -1) {
                        setIsEnabled(true);
                    }
                })
            }} srcDoc="<html>
              <body>Hello, <b>world</b>.</body>
           </html>" />}
        </Frame>
    )
}