import { Frame } from "../../Frame";
import {CardTemplate} from "../CardTemplate";
import {useWindowEvent} from "./useWindowEvent";
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {GrantAccess} from "./GrantAccess";

export function OpenSidebar() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const check = useCallback(() => {
        setIsSidebarOpen((window.outerWidth - window.innerWidth) > 200)
    }, []);

    useWindowEvent("resize", () => {
        check();
    });

    useEffect(() => {
        check();
    }, [check]);

    if(isSidebarOpen) {
        return <GrantAccess />
    }

    return (
        <div>
            <Frame>
                <CardTemplate title={"Open Testing Taxi"} description={"Go ahead and open the right-click menu"}
                              next={"/setup/grant-access"}
                              nextSoftDisabled={!isSidebarOpen}>
                    <img style={{width: "100%", opacity: isSidebarOpen ? 0.5 : 1}} src={require("./context-menu.jpeg")} alt="Open context menu" />
                </CardTemplate>
            </Frame>
        </div>
    )
}