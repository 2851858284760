import {useDropzone} from 'react-dropzone'
import {blue, grey} from "@mui/material/colors";

export function DropZone() {
    const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone()

    return (
        <div {...getRootProps()} style={{padding: 16, paddingTop: 8, paddingBottom: 8,
                border: isDragActive ? "2px solid " + blue["400"] : "2px solid " + grey["400"],
                borderRadius: 6, backgroundColor: grey["100"], fontSize: "0.8rem"}}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag 'n' drop some files here, or click to select files</p>
            }

            {acceptedFiles.map(f => {
                return <div style={{
                    padding: 8
                }}>
                    {f.name}
                </div>
            })}
        </div>
    )
}